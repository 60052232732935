import NetworkTransport from "../lib/network-transport/lib";
import buildEnhancedFetch from "../utils/fetchUtil";

const ENV = (process.env.REACT_APP_ENVIRONMENT || "").toUpperCase();

let _HOST, _PORT, _PREFIX, _PROTOCOL, _OWN_PREFIX;

switch (ENV) {
  case "DEV":
    _HOST = "api.dev.oredi-sensor.com";
    _PREFIX = "";
    _PROTOCOL = "https";
    _OWN_PREFIX = "";
    break;
  case "UAT":
    _HOST = "api.internal.oredi-sensor.com";
    _PREFIX = "";
    _PROTOCOL = "https";
    _OWN_PREFIX = "";
    break;
  case "MAIN": //as production
    _HOST = "api.core.oredi-sensor.com";
    _PREFIX = "";
    _PROTOCOL = "https";
    _OWN_PREFIX = "";
    break;
  default:
    _HOST = "localhost";
    _PORT = 8081;
    _PREFIX = "";
    _PROTOCOL = "http";
    _OWN_PREFIX = "";
}

export const HOST = _HOST;
export const PORT = _PORT;
export const PREFIX = _PREFIX;
export const PROTOCOL = _PROTOCOL;
export const OWN_PREFIX = _OWN_PREFIX;

export const URL = `${PROTOCOL}://${HOST}${PORT ? ":" + PORT : ""}/`;

const networkTransport = new NetworkTransport(URL, {
  autoConnectSocket: false
});

export const api = {
  networkTransport,
  fetch: buildEnhancedFetch(networkTransport),
  JSONAPIformat: function(item, type) {
    if (item instanceof Array) {
      return item.map(element => {
        return { id: element.id, type: type, data: element };
      });
    } else {
      return [{ id: item.id, type: type, data: item }];
    }
  }
};

export default api;
