// 'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof =
  typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
    ? function(obj) {
        return typeof obj;
      }
    : function(obj) {
        return obj &&
          typeof Symbol === "function" &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? "symbol"
          : typeof obj;
      };

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

/**
 * -----------------------------------------------------------------------------
 * JavaScript Client (SDK) for communicating with a REST server using Socket.IO.
 * -----------------------------------------------------------------------------
 */

// Imports

var _url = require("url");

var _url2 = _interopRequireDefault(_url);

var _socket = require("socket.io-client");

var _socket2 = _interopRequireDefault(_socket);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

/**
 * Logs a debug message.
 *
 * @param msg Message.
 */
var debug = function debug(msg) {
  if (process.env.NODE_ENV !== "production") {
    console.log(msg); // eslint-disable-line no-console
  }
};

/**
 * Socket Response
 */

var Response = (function() {
  /**
   * Constructs a socket response from the raw Socket.IO message body.
   *
   * @param url {string} URL of the request.
   * @param body {*} Socket.IO message body.
   */
  function Response(url, body) {
    _classCallCheck(this, Response);

    this.type = "default";
    this.url = url;
    this.status = body.statusCode;
    this.statusText = undefined;
    this.headers = undefined;
    this.body = body.payload;
    this.bodyUsed = false;
    this.ok = this.status >= 200 && this.status < 300;
  }

  _createClass(Response, [
    {
      key: "json",
      value: function json() {
        return Promise.resolve(this.body);
      }
    },
    {
      key: "text",
      value: function text() {
        return Promise.resolve(this.body);
      }
    },
    {
      key: "blob",
      value: function blob() {
        return Promise.resolve(new Blob([this.body]));
      }
    }
  ]);

  return Response;
})();

/**
 * RESTSocket
 *
 * A wrapper for an underlying Socket.IO client instance that simulates a REST client interface.
 */

var RESTSocket = (function() {
  function RESTSocket(url, options) {
    _classCallCheck(this, RESTSocket);

    // Ensure URL has no trailing slash
    this.url = url.replace(/(\/)$/, "");

    // Populate the options
    this.options = options || {};

    // Set up a request queue to hold requests made prior to creating the underlying socket
    this.requestQueue = [];

    // Set up an event listeners map to hold listeners bound prior to creating the
    // underlying socket
    this.eventListeners = new Map();
  }

  /**
   * Authenticates the Socket.IO connection using the specified token.
   *
   * @param token JSON Web Token.
   */

  _createClass(RESTSocket, [
    {
      key: "authenticate",
      value: function authenticate(token) {
        var _this = this;

        return new Promise(function(resolve) {
          _this._emit("authenticate", token, function() {
            resolve();
          });
        });
      }

      /**
       * Deauthenticates the Socket.IO connection.
       */
    },
    {
      key: "deauthenticate",
      value: function deauthenticate() {
        var _this2 = this;

        return new Promise(function(resolve) {
          _this2._emit("deauthenticate", {}, function() {
            resolve();
          });
        });
      }

      /**
       * Connects the socket to the server.
       */
    },
    {
      key: "connect",
      value: function connect() {
        var _this3 = this;

        this.isConnecting = true;

        // Create and connect the underlying socket
        this._raw = (0, _socket2.default)(this.url, this.options);

        // Replay any event listener bindings and requests made prior to creating the underlying
        // socket
        this._replay();

        this.on("connect", function() {
          _this3.isConnecting = false;
          debug("[Socket.IO] Connected to hapi.\n\n");
        });

        this.on("disconnect", function() {
          _this3.connectionLostTimestamp = new Date().getTime();
          debug("[Socket.IO] Disconnected from hapi.\n\n");
        });

        this.on("reconnecting", function(numAttempts) {
          debug(
            "[Socket.IO] Trying to reconnect to hapi (attempt #" +
              numAttempts +
              ")...\n\n"
          );
        });

        this.on("reconnect", function() {
          var msSinceConnectionLost =
            new Date().getTime() - _this3.connectionLostTimestamp;
          var numSecsOffline = msSinceConnectionLost / 1000;
          debug(
            "[Socket.IO] Reconnected successfully after being offline for ~" +
              numSecsOffline +
              "' seconds.' + '\n\n"
          );
        });

        this.on("error", function(err) {
          _this3.isConnecting = false;
          debug("[Socket.IO] Failed to connect to hapi (" + err + ")\n\n");
        });
      }

      /**
       * Reconnects a disconnected socket to the server.
       */
    },
    {
      key: "reconnect",
      value: function reconnect() {
        if (this.isConnecting) {
          throw new Error("Cannot connect: Socket is already connecting.");
        }

        if (this.isConnected()) {
          throw new Error("Cannot connect: Socket is already connected.");
        }

        this.connect();
      }

      /**
       * Disconnects the socket from the server.
       */
    },
    {
      key: "disconnect",
      value: function disconnect() {
        this.isConnecting = false;
        if (!this.isConnected()) {
          throw new Error("Cannot disconnect: Socket is already disconnected.");
        }

        return this._raw.disconnect();
      }

      /**
       * Queries if the socket is connected to the server.
       *
       * @returns {boolean} true if the socket is connected, false if not.
       */
    },
    {
      key: "isConnected",
      value: function isConnected() {
        if (!this._raw) {
          return false;
        }

        return this._raw.connected;
      }

      /**
       * Binds an event listener to the socket (chainable).
       *
       * @param event Event name.
       * @param listenerFn Event listener function.
       * @returns {RESTSocket} Socket.
       */
    },
    {
      key: "on",
      value: function on(event, listenerFn) {
        // If possible, bind the event listener to the raw underlying socket.
        if (this._raw) {
          this._raw.on(event, listenerFn);
          return this;
        }

        // Otherwise, add it to the event listeners map.
        var eventListenerFns = this.eventListeners.get(event) || [];
        eventListenerFns.push(listenerFn);
        this.eventListeners.set(event, eventListenerFns);

        return this;
      }

      /**
       * Unbinds an event listener from the socket (chainable).
       *
       * @param event Event name.
       * @param listenerFn Event listener function to unbind, or null to unbind all functions.
       * @returns {RESTSocket} Socket.
       */
    },
    {
      key: "off",
      value: function off(event, listenerFn) {
        // If possible, unbind the event listener from the raw underlying socket.
        if (this._raw) {
          this._raw.off(event, listenerFn);
          return this;
        }

        // If no listener function was provided, remove all listeners for the event.
        if (typeof listenerFn === "undefined" || listenerFn === null) {
          this.eventListeners.delete(event);
          return this;
        }

        // Otherwise, remove the listener from the event listeners map.
        var eventListenerFns = this.eventListeners.get(event);
        if (eventListenerFns) {
          var eventListenerIndex = eventListenerFns.indexOf(listenerFn);
          if (eventListenerIndex > -1) {
            this.eventListeners.set(
              event,
              eventListenerFns.splice(eventListenerIndex, 1)
            );
          }
        }

        return this;
      }

      /**
       * Unbinds all event listeners from the socket (chainable).
       *
       * @return {RESTSocket}
       */
    },
    {
      key: "removeAllListeners",
      value: function removeAllListeners() {
        // If possible, unbind remove all listeners from the raw underlying socket.
        if (this._raw) {
          this._raw.removeAllListeners();
          return this;
        }

        // Otherwise, clear the event handlers map.
        this.eventListeners.clear();

        return this;
      }

      /**
       * Routes an HTTP GET request via Socket.IO.
       *
       * @param path {string} Server destination path.
       * @returns {Promise}
       */
    },
    {
      key: "get",
      value: function get(path) {
        return this.fetch(path, { method: "GET" });
      }

      /**
       * Routes an HTTP POST request via Socket.IO.
       *
       * @param path {string} Server destination path.
       * @param body {*} Body of the request (optional).
       * @returns {Promise}
       */
    },
    {
      key: "post",
      value: function post(path, body) {
        return this.fetch(path, { method: "POST", body: body });
      }

      /**
       * Routes an HTTP PUT request via Socket.IO.
       *
       * @param path {string} Server destination path.
       * @param body {*} Body of the request (optional).
       * @returns {Promise}
       */
    },
    {
      key: "put",
      value: function put(path, body) {
        return this.fetch(path, { method: "PUT", body: body });
      }

      /**
       * Routes an HTTP DELETE request via Socket.IO.
       *
       * @param path {string} Server destination path.
       * @param body {*} Body of the request (optional).
       * @returns {Promise}
       */
    },
    {
      key: "delete",
      value: function _delete(path, body) {
        return this.fetch(path, { method: "DELETE", body: body });
      }

      /**
       * Routes an HTTP PATCH request via Socket.IO.
       *
       * @param path {string} Server destination path.
       * @param body {*} Body of the request (optional).
       * @returns {Promise}
       */
    },
    {
      key: "patch",
      value: function patch(path, body) {
        return this.fetch(path, { method: "PATCH", body: body });
      }

      /**
       * Routes an HTTP request via Socket.IO.
       *
       * @param path {string} Server destination path.
       * @param options Request options.
       * @returns {Promise}
       */
    },
    {
      key: "fetch",
      value: function fetch(path, options) {
        var _this4 = this;

        return new Promise(function(resolve, reject) {
          try {
            // Validate path
            if (typeof path !== "string") {
              reject(new Error("Invalid or missing path."));
              return;
            }

            // Validate options (optional)
            if (
              options &&
              (typeof options === "undefined"
                ? "undefined"
                : _typeof(options)) !== "object"
            ) {
              reject(new Error("Options is not an object."));
              return;
            }

            // Validate HTTP method (optional)
            if (options.method && typeof options.method !== "string") {
              reject(new Error("Invalid HTTP method."));
              return;
            }

            // Set the event name to the HTTP method (uppercase)
            // eslint-disable-next-line no-mixed-operators
            var event =
              (options.method && options.method.toUpperCase()) || "GET";

            // Build the Socket.IO message
            var message = {
              // Remove trailing slashes and spaces from the path to make packets smaller.
              url: path.replace(/^(.+)\/*\s*$/, "$1"),

              // Populate the payload
              payload: options.body
            };

            // Emit the Socket.IO event
            _this4._emit(event, message, function(reply) {
              resolve(
                new Response(_url2.default.resolve(_this4.url, path), reply)
              );
            });
          } catch (err) {
            reject(err);
          }
        });
      }

      /**
       * Emits a Socket.IO event.
       *
       * @param event Event name.
       * @param message Message (optional).
       * @param cb Callback function to call when finished (optional).
       * @private
       */
    },
    {
      key: "_emit",
      value: function _emit(event, message, cb) {
        // If possible, emit the event, otherwise queue it.
        if (this._raw) {
          this._raw.emit(event, message, function(reply) {
            if (cb) {
              cb(reply);
            }
          });
        } else {
          this.requestQueue = this.requestQueue || [];
          this.requestQueue.push([event, message, cb]);
        }
      }

      /**
       * Replays the event listener bindings and requests made prior to creating the underlying raw
       * socket.
       *
       * @private
       */
    },
    {
      key: "_replay",
      value: function _replay() {
        var _this5 = this;

        // Bind events
        this.eventListeners.forEach(function(listeners, event) {
          listeners.forEach(function(listener) {
            _this5._raw.on(event, listener);
          });
        });

        // Run the request queue
        while (this.requestQueue.length > 0) {
          var request = this.requestQueue.shift();
          this._emit.apply(this, _toConsumableArray(request));
        }
      }
    }
  ]);

  return RESTSocket;
})();

exports.default = RESTSocket;
