import React from "react";
import ContextDevTool from "react-context-devtool";
import State from "./database-helpers/state";
import Model from "./database-helpers/model";

export const Store = React.createContext();

const initialState = {};

//todo: make a good merge
function reducer(state, action) {
  const { data } = action;

  switch (action.type) {
    case "ADD_MULTIPLE":
      for (const key in data) {
        const instance = data[key];
        Model.checkType(state, instance);
      }
      return new State(state);
    case "ADD_SINGLE":
      Model.checkType(state, data);
      return new State(state);
    case "PATCH":
      Model.checkType(state, data);
      return new State(state);
    case "DELETE":
      if (state[data.__type__]) {
        delete state[data.__type__][data.id];
      }
      return new State(state);
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [database, dispatch] = React.useReducer(reducer, initialState);
  const value = { database, dispatch };

  return (
    <Store.Provider value={value}>
      <ContextDevTool
        context={Store}
        id="uniqContextId"
        displayName="Context Display Name"
      />
      {props.children}
    </Store.Provider>
  );
}
