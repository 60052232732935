import { createSelector } from "reselect";

const selectEntity = state => state.entities.language;
const selectEntityData = state =>
  state.entities.language && state.entities.language.data
    ? Object.values(state.entities.language.data)
    : {};
const selectReduxForm = state => state.form;
const selectLanguage = state => state.global.language;

export const makeSelectLanguages = () =>
  createSelector([selectEntityData], substate => substate);

export const makeSelectReduxForm = () =>
  createSelector([selectReduxForm], substate => substate);

export const makeSelectLanguageCode = () =>
  createSelector([selectLanguage], substate => substate.locale);

export const makeSelectIsLanguagesLoading = () =>
  createSelector(selectEntity, substate => {
    if (substate) return substate.isLoading;
    return true;
  });
