import React, { createContext, useEffect, useState } from "react";
import { Prompt, withRouter } from "react-router-dom";

export const PromptContext = createContext();

const handleBeforeUnload = event => {
  event.preventDefault();
  event.returnValue = true;
};

// Used this Context to prevent muiltiple Prompt elements from overlapping when changing routes
const PromptProvider = ({ history, children }) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [message, setMessage] = useState(
    "Are you sure you want to leave?\nChanges you made may not be saved."
  );

  // remove blocking when user accepted page change
  useEffect(() => {
    if (isBlocking) {
      unblock();
    }
  }, [history.location.pathname]);

  const block = () => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    setIsBlocking(true);
  };

  const unblock = () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
    setIsBlocking(false);
  };

  return (
    <PromptContext.Provider value={{ isBlocking, block, unblock, setMessage }}>
      {children}
      <Prompt when={isBlocking} message={message} />
    </PromptContext.Provider>
  );
};

export default withRouter(PromptProvider);
