import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { bindActionCreators } from "redux";
import { makeSelectLocale } from "translations/language-provider/selectors";
import { changeLocale } from "translations/language-provider/actions";
import {
  getLanguages,
  makeSelectLanguages,
  makeSelectIsLanguagesLoading,
  textTranslation
} from "entities/languages";
import ReactCountryFlag from "react-country-flag";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./styles.css";

const LanguageSelector = props => {
  const {
    style,
    locale,
    source,
    disabled,
    loading,
    languages,
    message,
    setMessage,
    setLoading,
    setLanguage,
    changeLocale,
    getLanguages,
    textTranslation
  } = props;

  useEffect(() => {
    getLanguages();
  }, [getLanguages]);

  const handleMenuClick = e => {
    if (!e.key) return;

    if (setMessage && message) {
      setLoading(true);
      textTranslation(message, source, e.key).then(response => {
        setMessage(response);
        setLoading(false);
      });
      setLanguage(e.key);
    } else {
      changeLocale(e.key);
    }
  };

  if (loading || !Array.isArray(languages)) {
    return (
      <FormControl disabled={disabled} style={{ ...style }}>
        <Select
          sx={{ paddingRight: "unset" }}
          disabled
          value={"loading"}
          IconComponent={() => null}
          className="sayn-language-selector"
        >
          <MenuItem value={"loading"}>
            <div style={{ width: "24px" }} />
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <FormControl disabled={disabled} style={{ ...style }}>
      <Select
        inputProps={{ "aria-label": "Select language" }}
        value={source || locale}
        onChange={handleMenuClick}
        IconComponent={() => null}
        className="sayn-language-selector"
      >
        {languages.map(lang => {
          return (
            <MenuItem
              key={lang.code}
              onClick={() => handleMenuClick({ key: lang.code })}
              value={lang.code}
            >
              <ReactCountryFlag
                svg
                style={{
                  width: "1.5em",
                  height: "1.5em"
                }}
                title={lang.code.toUpperCase()}
                countryCode={
                  lang.code.toUpperCase() === "EN"
                    ? "GB"
                    : lang.code.toUpperCase()
                }
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  languages: makeSelectLanguages(),
  loading: makeSelectIsLanguagesLoading()
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeLocale,
      getLanguages,
      textTranslation
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
