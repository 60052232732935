/**
 * Create the store with dynamic reducers
 */

import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
//import logger from 'redux-logger';
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from "redux-thunk";

import { api } from "../api/index";
import createReducer from "../reducer/reducers";

// console.log(api, HOST);

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ["global"]
};

const persistedReducer = persistReducer(persistConfig, createReducer());

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    thunk.withExtraArgument(api),
    routerMiddleware(history) /*logger*/
  ];
  // const reducer = createReducer();
  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
          // actionsBlacklist: ['REDUX_STORAGE_LOAD', 'REDUX_STORAGE_SAVE'],
          serialize: {
            options: true,
            replacer: (key, value) => {
              if (value instanceof Map) {
                const object = {};
                value.forEach(
                  (innerValue, innerKey) => (object[innerKey] = innerValue)
                );
                return object;
              }
              return value;
            }
          }
        })
      : compose;
  /* eslint-enable */

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  //test
  window.dispatch = store.dispatch;
  store.persistor = persistStore(store, null, () => {
    const state = store.getState();
    // If there is a token on the store, authenticate on the server
    if (state.global && state.global.auth && state.global.auth.token) {
      api.networkTransport.authenticate(state.global.auth.token);
    } else {
      api.networkTransport.deauthenticate();
    }
  });

  return store;
}
