import React from "react";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  DatabaseOutlined,
  ExperimentOutlined,
  FileOutlined,
  TeamOutlined,
  SendOutlined,
  EllipsisOutlined,
  FormOutlined
} from "@ant-design/icons";
import languageKeys from "../translations";

const AdminMenu = ({ selectedKeys, links, translate }) => {
  const key = (selectedKeys || [])[0];
  const openKeys =
    key &&
    key
      .split(".")
      .slice(0, -1)
      .reduce((keys, _, i, arr) => {
        keys.push(arr.slice(0, i + 1).join("."));
        return keys;
      }, []);

  return (
    <Menu
      key={"Menu"}
      style={{
        background: "transparent",
        height: "calc(100% - 94px)",
        overflowX: "hidden",
        overflowY: "auto"
      }}
      inlineIndent={12}
      theme="light"
      mode="inline"
      defaultSelectedKeys={selectedKeys || []}
      defaultOpenKeys={openKeys}
    >
      <Menu.Item key="A1">{links.history}</Menu.Item>
      <Menu.Item key="A2">{links.answerQuestionnaire}</Menu.Item>
      <Menu.Item key="A3">{links.invites}</Menu.Item>
      <SubMenu
        style={{ paddingLeft: 0, fontSize: 11 }}
        key="A4"
        title={
          <span style={{ paddingLeft: 0, fontSize: 13 }}>
            <EllipsisOutlined />
            <span>{translate(languageKeys.otherMenu)}</span>
          </span>
        }
      >
        <Menu.Item key="A4.1">{links.backofficeValues}</Menu.Item>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="A4.2"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <TeamOutlined />
              <span>{translate(languageKeys.usersMenu)}</span>
            </span>
          }
        >
          <Menu.Item key="A4.2.3">{links.pacients}</Menu.Item>
          <Menu.Item key="A4.2.1">{links.usersBackoffice}</Menu.Item>
          <Menu.Item key="A4.2.2">{links.usersApp}</Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="A4.11"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <FormOutlined />
              <span>{translate(languageKeys.contentManagement)}</span>
            </span>
          }
        >
          <Menu.Item key="A4.11.1">{links.emailTemplates}</Menu.Item>
          <Menu.Item key="A4.11.2">{links.customTexts}</Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="A4.3"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <DatabaseOutlined />
              <span>{translate(languageKeys.data_base)}</span>
            </span>
          }
        >
          <Menu.Item key="A4.3.1">{links.questionnaires}</Menu.Item>
          <Menu.Item key="A4.3.2">{links.questions}</Menu.Item>
          <Menu.Item key="A4.3.3">{links.answersOptions}</Menu.Item>
          <Menu.Item key="A4.3.4">{links.gallery}</Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="A4.4"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <ExperimentOutlined />
              <span>{translate(languageKeys.algorithmMenu)}</span>
            </span>
          }
        >
          <Menu.Item key="A4.4.1">{links.criteria}</Menu.Item>
          <Menu.Item key="A4.4.2">{links.criteriaUsage}</Menu.Item>
          <Menu.Item key="A4.4.3">{links.metaSkill}</Menu.Item>
          <Menu.Item key="A4.4.4">{links.principle}</Menu.Item>
          <Menu.Item key="A4.4.5">{links.algorithmConfig}</Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="A4.5"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <ExperimentOutlined />
              <span>{translate(languageKeys.secondaryAlgorithmMenu)}</span>
            </span>
          }
        >
          <Menu.Item key="A4.5.1">{links.element}</Menu.Item>
          <SubMenu
            style={{ paddingLeft: 0, fontSize: 11 }}
            key="A4.5.2"
            title={
              <span style={{ paddingLeft: 0, fontSize: 13 }}>
                <FileOutlined />
                <span>{translate(languageKeys.attributesMenu)}</span>
              </span>
            }
          >
            <Menu.Item key="A4.5.2.1">{links.feeling}</Menu.Item>
            <Menu.Item key="A4.5.2.2">{links.organ}</Menu.Item>
            <Menu.Item key="A4.5.2.3">{links.season}</Menu.Item>
            <Menu.Item key="A4.5.2.4">{links.naturalElement}</Menu.Item>
            <Menu.Item key="A4.5.2.5">{links.character}</Menu.Item>
          </SubMenu>
        </SubMenu>
        <Menu.Item key="A4.6">{links.input}</Menu.Item>
        <SubMenu
          style={{ paddingLeft: 0, fontSize: 11 }}
          key="A4.7"
          title={
            <span style={{ paddingLeft: 0, fontSize: 13 }}>
              <SendOutlined />
              <span>{translate(languageKeys.outputMenu)}</span>
            </span>
          }
        >
          <Menu.Item key="A4.7.1">{links.algorithmOutput}</Menu.Item>
          <Menu.Item key="A4.7.2">{links.generalOutput}</Menu.Item>
        </SubMenu>
        <Menu.Item key="A4.8">{links.pushNotifications}</Menu.Item>
        <Menu.Item key="A4.9">{links.app}</Menu.Item>
        <Menu.Item key="A4.10">{links.criteriaManagement}</Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default AdminMenu;
