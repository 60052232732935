import { globalKeys } from "../../../translations/language-provider/translations";
import keys from "./keys";

import en from "./en-US";
import pt from "./pt-PT";
import fr from "./fr-FR";

const languageKeys = { ...globalKeys, ...keys };

export default languageKeys;

export const messages = { pt, en, fr };
