import {
  SET_AUTH,
  SET_ERROR,
  SET_LOADING,
  SET_SUPPLIER,
  SET_TOKEN,
  SET_USERNAME,
  SET_PROFILE,
  SET_ROLE
} from "./constants";

const initialState = {
  profile: null,
  auth: false,
  token: null,
  error: null,
  loading: false,
  username: null,
  role: "none"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, ...{ loading: action.payload.loading } };
    case SET_PROFILE:
      return { ...state, ...{ profile: action.payload.profile } };
    case SET_ERROR:
      return { ...state, ...{ error: action.payload.error, loading: false } };
    case SET_TOKEN:
      return { ...state, ...{ token: action.payload.token } };
    case SET_AUTH:
      return { ...state, ...{ auth: action.payload.auth } };
    case SET_USERNAME:
      return { ...state, ...{ username: action.payload.username } };
    case SET_ROLE:
      return { ...state, ...{ role: action.payload.role } };
    case SET_SUPPLIER:
      return { ...state, ...{ supplier: action.payload.supplierId } };

    default:
      return state;
  }
}
