/**
 * @flow
 */

import { actionTypes } from "./actions";

const defaultOptions = {
  createEntityMaps: true,
  dataConverters: {}
};

const createEntitiesReducer = (options = {}) => {
  const settings = { ...defaultOptions, ...options };

  return (state = {}, action) => {
    switch (action.type) {
      case actionTypes.ACTION_TYPE_SET_LOADING:
        if (!state[action.payload.type] && !settings.createEntityMaps) {
          break;
        }

        if (action.payload.loadingId === undefined) {
          alert(
            "You developer!! dont use method setLoading of entities, use load instead please!"
          );
          return state;
        }
        const entityState = state[action.payload.type] || {
          data: {},
          isLoading: false,
          loadingId: action.payload.loadingId,
          loadingIds: []
        };

        if (action.payload.isLoading) {
          entityState.loadingIds.push(action.payload.loadingId);
        } else {
          entityState.loadingIds.splice(
            entityState.loadingIds.indexOf(action.payload.loadingId),
            1
          );
        }

        return {
          ...state,
          [action.payload.type]: {
            ...entityState,
            isLoading: entityState.loadingIds.length > 0,
            loadingId: action.payload.loadingId
          }
        };

      case actionTypes.ACTION_TYPE_SET_ERROR:
        if (!state[action.payload.type] && !settings.createEntityMaps) {
          break;
        }

        return {
          ...state,
          [action.payload.type]: {
            ...state[action.payload.type],
            error: action.payload.error
          }
        };

      case actionTypes.ACTION_TYPE_ADD: {
        const modifiedEntities = {};

        const groupedEntities = action.payload.reduce((accumulator, entity) => {
          if (entity)
            (accumulator[entity.type] = accumulator[entity.type] || []).push(
              entity
            );
          return accumulator;
        }, {});

        Object.entries(groupedEntities).forEach(([entityType, entities]) => {
          if (entityType in state) {
            modifiedEntities[entityType] = {
              ...state[entityType],
              ...{
                data: { ...state[entityType].data }
              }
            };
          } else if (settings.createEntityMaps) {
            modifiedEntities[entityType] = {
              data: {},
              error: null,
              isLoading: false,
              loadingIds: []
            };
          } else {
            return;
          }

          const dataConverter = options.dataConverters
            ? options.dataConverters[entityType] || Object
            : Object;

          entities.forEach(entity => {
            modifiedEntities[entityType].data[entity.id] = dataConverter({
              id: entity.id,
              ...entity.data
            });
          });
        });

        if (Object.keys(modifiedEntities).length !== 0) {
          return {
            ...state,
            ...modifiedEntities
          };
        }

        break;
      }

      case actionTypes.ACTION_TYPE_REMOVE: {
        if (
          state[action.payload.type] &&
          state[action.payload.type].data[action.payload.id]
        ) {
          const newState = state[action.payload.type];
          delete newState.data[action.payload.id];

          return {
            ...state,
            [action.payload.type]: {
              ...newState
            }
          };
        }

        break;
      }

      case actionTypes.ACTION_TYPE_CLEAR: {
        if (action.payload.type in state) {
          return {
            ...state,
            [action.payload.type]: {
              ...state[action.payload.type],
              data: new Map()
            }
          };
        }

        break;
      }

      default:
        break;
    }

    return state;
  };
};

export { add, remove, clear, load } from "./actions";

export default createEntitiesReducer;
