import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import jwt from "jwt-decode";
import { createStructuredSelector } from "reselect";
import { Layout, Breadcrumb } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import LanguageSelector from "../LanguageSelector";
import classes from "./style.css";
import logoText from "assets/images/icon.png";
import logo from "assets/images/icon.png";
import Background from "assets/images/menu.png";
import { makeSelectLocale } from "../../translations/language-provider/selectors";
import UserMenu from "../../components/DropdownUserMenu";
import { updatePassword } from "lib/providers/auth-provider/actions";
import {
  makeSelectToken,
  makeSelectRole,
  makeSelectIsAuth
} from "lib/providers/auth-provider/selectors";
import AdminMenu from "./menus/adminMenu";
import ConsultantMenu from "./menus/consultantMenu";
import links from "./menus/links";

const { Header, Sider, Content } = Layout;

const MasterPageLayout = ({
  breadcrumb = [],
  level,
  name,
  role,
  token,
  keys,
  logout,
  intl,
  children
}) => {
  const translate = id => intl.formatMessage({ id });

  const [collapsed, setCollapsed] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (token) {
      setUserId(jwt(token).sub);
    }
  }, [token]);

  const toggle = () => {
    setCollapsed(prev => !prev);
  };

  const menuProps = {
    key: "Menu",
    userId: userId,
    links: links({ collapsed, userId }, translate),
    translate: translate,
    ...keys
  };

  return (
    <Layout key={"admin-page"} className={classes.layout}>
      <Sider
        className={classes.sider}
        style={{
          backgroundImage: `url(${Background})`
        }}
        width={230}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className={classes.logo}>
          <img alt="sensor logo" src={collapsed ? logo : logoText} />
        </div>
        {role === "admin" ? (
          <AdminMenu {...menuProps} />
        ) : (
          <ConsultantMenu {...menuProps} />
        )}
      </Sider>
      <Layout>
        <Header className={classes.header}>
          {collapsed ? (
            <MenuUnfoldOutlined
              className="trigger"
              style={{ verticalAlign: "unset", lineHeight: 0 }}
              onClick={toggle}
            />
          ) : (
            <MenuFoldOutlined
              className="trigger"
              style={{ verticalAlign: "unset", lineHeight: 0 }}
              onClick={toggle}
            />
          )}
          <div className={classes.left}>
            <Breadcrumb>
              {/* Use when we have homepage */}
              {/* <Breadcrumb.Item>
                  <HomeOutlined
                    onClick={() => history.push("/")}
                  />
                </Breadcrumb.Item> */}
              {level ? (
                <Breadcrumb.Item>{translate(level)}</Breadcrumb.Item>
              ) : null}
              {breadcrumb.map(path => (
                <Breadcrumb.Item key={path}>
                  {links({ collapsed, userId }, translate)[path]}
                </Breadcrumb.Item>
              ))}
              {name && (
                <Breadcrumb.Item className={classes.breadcrumbName}>
                  {translate(name)}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </div>
          <div className={classes.right}>
            <LanguageSelector />
            <span style={{ paddingLeft: "20px" }}>
              <UserMenu userId={userId} token={token} logout={logout} />
            </span>
          </div>
        </Header>
        <Content className={classes.content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
  token: makeSelectToken(),
  role: makeSelectRole(),
  auth: makeSelectIsAuth()
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updatePassword
    },
    dispatch
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withRouter(injectIntl(MasterPageLayout)));
