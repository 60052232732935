import React, { Component } from "react";
import "antd/dist/antd.min.css";
import LanguageProvider from "./translations/language-provider";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes";

import "./assets/css/index.css";
import "./assets/fonts/fonts.css";
import { unregister } from "./service-workers/registerServiceWorker";

import { StoreProvider as DatabaseProvider } from "./store/database";
import store from "./store/";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <LanguageProvider>
            <DatabaseProvider>
              <Routes />
            </DatabaseProvider>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
