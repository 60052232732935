//import JSONAPI from 'jsonapi-serializer';

function JSONAPIDESESIALIZER(settings, data) {
  function parseRelationship(relations) {
    const relationsParsed = {};
    for (let key in relations) {
      relationsParsed[key] = relations[key].data;
    }

    return relationsParsed;
  }

  try {
    let dataMaped;
    if (Array.isArray(data.data)) {
      dataMaped = data.data.map(item => {
        return {
          id: item.id,
          type: item.type,
          data: {
            ...item.attributes,
            ...parseRelationship(item.relationships)
          }
        };
      });
    } else {
      const item = data.data;
      dataMaped = [
        {
          id: item.id,
          type: item.type,
          data: {
            ...item.attributes,
            ...parseRelationship(item.relationships)
          }
        }
      ];
    }

    const included = data.included
      ? data.included.map(item => {
          return {
            id: item.id,
            type: item.type,
            data: {
              ...item.attributes,
              ...parseRelationship(item.relationships)
            }
          };
        })
      : [];

    return [...dataMaped, ...included];
  } catch (e) {
    console.warn(e);
    return [];
  }
}

const flatten = list =>
  list.reduce(
    (accumulator, entry) =>
      accumulator.concat(Array.isArray(entry) ? flatten(entry) : entry),
    []
  );

const createDeserializer = options => {
  const optionsRelationshipAttributes =
    options && options.relationshipAttributes
      ? options.relationshipAttributes
      : [];

  const mergedOptions = {
    relationshipTypes: [
      "string_resource",
      "entity_status",
      "translations",
      "question",
      "question_format",
      "questionnaire_category",
      "question_tag",
      "question_format",
      "questionnaire_category_question",
      "question_resource_type",
      "question_resource",
      "question_option_type",
      "question_option",
      "question_option_answer",
      "question_option_type",
      "question_answer_options",
      "answer_option",
      "user",
      "image",
      "questionnaire",
      "question_answer_option",
      "translation",
      "user_answer",
      "questionnaire_answer",
      "alg_meta_skill",
      "alg_criteria_usage",
      "alg_criteria",
      "descriptions",
      "alg_principle",
      "questionnaire_category_question_criteria",
      "criteria",
      "principle",
      ...optionsRelationshipAttributes
    ],
    relationshipAttributes: [...optionsRelationshipAttributes],
    ...options
  };

  const settings = {
    keyForAttribute: "underscore_case",
    typeAsAttribute: true,
    transform: record => {
      const records = [];
      const processing = [record]; //for nested relations

      while (processing.length > 0) {
        record = processing.pop();
        // eslint-disable-next-line no-loop-func
        mergedOptions.relationshipAttributes.forEach(relationshipAttribute => {
          if (Array.isArray(record[relationshipAttribute])) {
            record[relationshipAttribute].forEach(
              (relationshipInstance, index) => {
                const { type, id, ...data } = relationshipInstance;
                record[relationshipAttribute][index] = { type, id };
                if (Object.keys(data).length > 0) {
                  processing.push({ ...data, type, id });
                }
              }
            );
          } else if (record[relationshipAttribute]) {
            const { type, id, ...data } = record[relationshipAttribute];
            record[relationshipAttribute] = { type, id };
            if (Object.keys(data).length > 0) {
              processing.push({ ...data, type, id });
            }
          }
        });

        const { type, id, ...data } = record;
        records.push({ type, id, data });
      }

      return records;
    }
  };

  const relationshipOptions = {
    valueForRelationship: (relationship, include) => {
      return include || relationship || null;
    }
  };

  mergedOptions.relationshipTypes.forEach(relationshipType => {
    settings[relationshipType] = relationshipOptions;
  });

  return async data => {
    return JSONAPIDESESIALIZER(settings, data);
    //const deserializedData = await new JSONAPI.Deserializer(settings)
    //.deserialize(data);

    //return flatten(deserializedData);
  };
};

export const supplierDeserialize = createDeserializer({
  relationshipAttributes: ["shipToAddress", "billingAddress"]
});

export const alertDeserialize = createDeserializer({
  relationshipAttributes: ["cabinet"]
});

export const cabinetDeserialize = createDeserializer({
  relationshipAttributes: [
    "address",
    "cabinetUnit",
    "channelStates",
    "equipmentType",
    "model",
    "orderConfiguration"
  ]
});

export const stockEventsDeserialize = createDeserializer({
  relationshipAttributes: ["channelState", "product", "cabinet"]
});

export const channelStatesDeserialize = createDeserializer({
  relationshipAttributes: ["product"]
});

export const generalDeserialize = createDeserializer({
  relationshipAttributes: []
});

export const questionnarieDeserialize = createDeserializer({
  relationshipAttributes: [
    "string_resources",
    "entity_status",
    "questions",
    "question",
    "categories",
    "language",
    "translations",
    "entity_status",
    "format",
    "option",
    "resources",
    "tags",
    "text",
    "sub_texts",
    "language",
    "images",
    "answer_options",
    "alg_criteria",
    "question_criteria",
    "criteria",
    "video",
    "principle",
    "alg_principle",
    "metaSkill",
    "criteriaUsage",
    "entityStatus",
    "questionCriteria",
    "descriptions"
  ]
});

export const questionnaireReducedDeserialize = createDeserializer({
  relationshipAttributes: [
    "string_resources",
    "entity_status",
    "categories",
    "language",
    "translations"
  ]
});

export const categoryDeserialize = createDeserializer({
  relationshipAttributes: ["string_resources", "language", "translations"]
});

export const questionDeserialize = createDeserializer({
  relationshipAttributes: [
    "question",
    "language",
    "translations",
    "answerOptions"
  ]
});
export const resourcesDeserialize = createDeserializer();

export const formatsDeserialize = createDeserializer();

export const optionsDeserialize = createDeserializer();

export const allQuestionsDeserialize = createDeserializer({
  relationshipAttributes: [
    "question",
    "text",
    "questionnaire",
    "questionnaire_category",
    "categories",
    "entityStatus",
    "format",
    "option",
    "string_resources",
    "resources",
    "tags",
    "text",
    "language",
    "translations",
    "answer_options",
    "images",
    "sub_texts"
  ]
});

export const answerOptionsDeserialize = createDeserializer({
  relationshipAttributes: [
    "string_resources",
    "user",
    "language",
    "translations",
    "entityStatus",
    "entity_status"
  ]
});

export const imageDeserialize = createDeserializer();

export const userAnswerDeserialize = createDeserializer({
  relationshipAttributes: [
    "questionnaire",
    "question",
    "questionnaire_category"
  ]
});

export const questionnaireAnswerDeserialize = createDeserializer({
  relationshipAttributes: [
    "questionnaire",
    "answers",
    "string_resources",
    "language",
    "translations",
    "categories",
    "questions",
    "question",
    "text",
    "answer_options",
    "alg_criteria",
    "question_criteria",
    "criteria",
    "principle",
    "user",
    "video"
  ]
});

export const allQuestionnaireAnswerDeserialize = createDeserializer({
  relationshipAttributes: [
    "questionnaire",
    "string_resources",
    "entity_status",
    "language",
    "translations"
  ]
});

export const stringResourcesDeserialize = createDeserializer({
  relationshipAttributes: ["language", "translations"]
});

export const criteriaDeserialize = createDeserializer({
  relationshipAttributes: [
    "entityStatus",
    "principle",
    "metaSkill",
    "criteriaUsage",
    "entity_status",
    "translations",
    "languages",
    "string_resources"
  ]
});

export const criteriaUsageDeserialize = createDeserializer({
  relationshipAttributes: [
    "entityStatus",
    "translations",
    "languages",
    "string_resources"
  ]
});

export const metaSkillDeserialize = createDeserializer({
  relationshipAttributes: [
    "entityStatus",
    "entity_status",
    "translations",
    "languages",
    "string_resources"
  ]
});

export const principleDeserialize = createDeserializer({
  relationshipAttributes: [
    "entityStatus",
    "translations",
    "languages",
    "string_resources"
  ]
});

export const faqDeserialize = createDeserializer();
