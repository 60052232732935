import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../utils/asyncComponent";
import { ConnectedRouter } from "react-router-redux";
import history from "../constants/global-history";
import { OWN_PREFIX } from "api/";
import { Helmet } from "react-helmet";
import PromptProvider from "../contexts/prompt";

//*const idRegex = '([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})';

const ACL = {
  login: {
    whitelist: ["none"],
    client: "/client/dashboard/",
    admin: "/user/1/answer-questionnaire/history",
    consultant: "/user/1/answer-questionnaire/history"
  },
  notFound: {
    whitelist: [],
    client: "/client/dashboard/",
    admin: "/admin/questionnaires",
    none: "/login",
    consultant: "/user/1/answer-questionnaire/history"
  },
  client: {
    whitelist: ["admin", "client"],
    none: "/login",
    consultant: "/admin/users/app"
  },
  any: { whitelist: ["admin", "client", "none", "consultant"] },
  admin: {
    whitelist: ["admin"],
    client: "/client/dashboard/",
    none: "/login",
    consultant: "/user/1/answer-questionnaire/history"
  },
  consultant: {
    whitelist: ["admin", "consultant"],
    consultant: "/user/1/answer-questionnaire/history",
    none: "/login"
  }
};

// Lazy loading the routes
const NotFoundPage = asyncComponent(() => import("./404-view"), ACL.any);
const Login = asyncComponent(() => import("./login-view"), ACL.login);
const AdminDashboard = asyncComponent(
  () => import("./admin-dashboard-view"),
  ACL.admin
);
const Questionnaires = asyncComponent(
  () => import("./questionnaires-view"),
  ACL.admin
);
const EditQuestionnaire = asyncComponent(
  () => import("./questionnaire-detail-view"),
  ACL.admin
);
const Questions = asyncComponent(() => import("./questions-view"), ACL.admin);
const UsersBackoffice = asyncComponent(
  () => import("./user-backoffice-view"),
  ACL.admin
);
const UsersApp = asyncComponent(() => import("./user-app-view"), ACL.admin);
// const UsersAnswers = asyncComponent(
//   () => import("./users-answer-view"),
//   ACL.admin
// );
const Pacients = asyncComponent(() => import("./pacients-view"), ACL.admin);
const AnswerOptions = asyncComponent(
  () => import("./answer-options-view"),
  ACL.admin
);
const NewEntry = asyncComponent(
  () => import("./answer-questionnaire-view"),
  ACL.consultant
);
const AnswerQuestionnaire = asyncComponent(
  () => import("./answer-questionnaire-selected-view"),
  ACL.consultant
);
const History = asyncComponent(
  () => import("./answer-history-view"),
  ACL.consultant
);
const Results = asyncComponent(
  () => import("./user-questionnaire-results"),
  ACL.consultant
);
const Criteria = asyncComponent(() => import("./alg-criteria-view"), ACL.admin);
const CriteriaUsage = asyncComponent(
  () => import("./alg-criteria-usage-view"),
  ACL.admin
);
const Principle = asyncComponent(
  () => import("./alg-principle-view"),
  ACL.admin
);
const MetaSkill = asyncComponent(
  () => import("./alg-meta-skill-view"),
  ACL.admin
);
const Elements = asyncComponent(() => import("./alg_elements"), ACL.admin);
const PrePaymentInvite = asyncComponent(
  () => import("./pre-payment-view"),
  ACL.any
);
const RemoteLogin = asyncComponent(
  () => import("./remote-login-view"),
  ACL.any
);
const LoginFromSite = asyncComponent(
  () => import("./login-from-site-view"),
  ACL.any
);
const RemotePassword = asyncComponent(
  () => import("./remote-login-password-view"),
  ACL.any
);
const RemotePasswordRecovery = asyncComponent(
  () => import("./remote-password-recovery-view"),
  ACL.any
);
const RemoteAnswer = asyncComponent(
  () => import("./remote-answer-view"),
  ACL.any
);
const RemoteAnswerCompleted = asyncComponent(
  () => import("./remote-answer-completed-view"),
  ACL.any
);
const Invites = asyncComponent(() => import("./invites-view"), ACL.admin);
const Feelings = asyncComponent(() => import("./feelings-view"), ACL.admin);
const Organs = asyncComponent(() => import("./organs-view"), ACL.admin);
const AppView = asyncComponent(() => import("./app-view"), ACL.admin);
const AlgorithmOutput = asyncComponent(
  () => import("./output-algorithm-view"),
  ACL.admin
);
const GeneralOutput = asyncComponent(
  () => import("./output-general-view"),
  ACL.admin
);
const CreateAlgorithmOutput = asyncComponent(
  () => import("./output-algorithm-add-view"),
  ACL.admin
);
const CreateGeneralOutput = asyncComponent(
  () => import("./output-general-add-view"),
  ACL.admin
);
const CreateInput = asyncComponent(() => import("./input-add-view"), ACL.admin);
const Input = asyncComponent(() => import("./input-view"), ACL.admin);
const Attributes = asyncComponent(() => import("./attributes-view"), ACL.admin);
const NaturalElements = asyncComponent(
  () => import("./natural-elements-view"),
  ACL.admin
);
const Seasons = asyncComponent(() => import("./season-view"), ACL.admin);
const Characters = asyncComponent(() => import("./character-view"), ACL.admin);
const Profile = asyncComponent(
  () => import("./user-profile-view"),
  ACL.consultant
);
const PrintAnalysis = asyncComponent(
  () => import("./print-analysis-view"),
  ACL.any
);
const PushNotifications = asyncComponent(
  () => import("./push-notifications-view"),
  ACL.admin
);
const MobileAnswerComponent = asyncComponent(
  () => import("./mobile-answer-component-view"),
  ACL.admin
);
const CriteriaManagement = asyncComponent(
  () => import("./criteria-management-view"),
  ACL.admin
);
const BackofficeValues = asyncComponent(
  () => import("./backoffice-values-view"),
  ACL.admin
);
const MobileAppsList = asyncComponent(
  () => import("./mobile-app-list-view"),
  ACL.admin
);
const MobileAppDashboard = asyncComponent(
  () => import("./mobile-app-management-view"),
  ACL.admin
);
const UserCriteriaApp = asyncComponent(
  () => import("./user-criteria-app-view"),
  ACL.admin
);
const MobileAppEnv = asyncComponent(
  () => import("./mobile-app-env-view"),
  ACL.admin
);
const TextConfigCriteria = asyncComponent(
  () => import("./criteria-text-config-view"),
  ACL.admin
);
const TextConfigCriteriaUsage = asyncComponent(
  () => import("./criteria-usage-text-config-view"),
  ACL.admin
);
const TextConfigMetaSkill = asyncComponent(
  () => import("./meta-skill-text-config-view"),
  ACL.admin
);
const TextConfigPrinciple = asyncComponent(
  () => import("./principle-text-config-view"),
  ACL.admin
);
const TextConfigElement = asyncComponent(
  () => import("./element-text-config-view"),
  ACL.admin
);
const TextConfigFeeling = asyncComponent(
  () => import("./feeling-text-config-view"),
  ACL.admin
);
const TextConfigOrgan = asyncComponent(
  () => import("./organ-text-config-view"),
  ACL.admin
);
const TextConfigSeason = asyncComponent(
  () => import("./season-text-config-view"),
  ACL.admin
);
const TextConfigNaturalElement = asyncComponent(
  () => import("./natural-element-text-config-view"),
  ACL.admin
);
const TextConfigCharacter = asyncComponent(
  () => import("./character-text-config-view"),
  ACL.admin
);
const MobileAppUser = asyncComponent(
  () => import("./mobile-app-user-view"),
  ACL.admin
);
const MobileAppHighlightType = asyncComponent(
  () => import("./mobile-app-highlight-type-view"),
  ACL.admin
);
const MobileAppHighlightOutput = asyncComponent(
  () => import("./mobile-app-highlight-output-view"),
  ACL.admin
);
const MobileAppHistory = asyncComponent(
  () => import("./mobile-app-history-view"),
  ACL.admin
);
const MobileAppHistoryUser = asyncComponent(
  () => import("./mobile-app-history-user-view"),
  ACL.admin
);
const MobileAppDailyChallenge = asyncComponent(
  () => import("./mobile-app-daily-challenge-view"),
  ACL.admin
);
const MobileAppHighlightQuestionnaire = asyncComponent(
  () => import("./mobile-app-highlight-questionnaire-view"),
  ACL.admin
);
const MobileAppHighlightQuestion = asyncComponent(
  () => import("./mobile-app-highlight-question-view"),
  ACL.admin
);
const StaticString = asyncComponent(
  () => import("./static-string-view"),
  ACL.admin
);
const MobileAppPushNotifications = asyncComponent(
  () => import("./mobile-app-push-notifications-view"),
  ACL.admin
);
const MobileAppFreeCriterias = asyncComponent(
  () => import("./mobile-app-free-criteria-view"),
  ACL.admin
);
const MobileAppHistoryQuestionnaire = asyncComponent(
  () => import("./mobile-app-history-questionnaire-view"),
  ACL.admin
);
const MobileAppHistoryResults = asyncComponent(
  () => import("./mobile-app-history-results-view"),
  ACL.admin
);
const Report = asyncComponent(() => import("./report-view"), ACL.consultant);
const MobileAppHistoryReport = asyncComponent(
  () => import("./mobile-app-history-report-view"),
  ACL.admin
);
const NewPassword = asyncComponent(
  () => import("./new-password-view"),
  ACL.any
);
const NewPasswordChanged = asyncComponent(
  () => import("./new-password-success-view"),
  ACL.any
);
const PasswordRecovery = asyncComponent(
  () => import("./password-recovery-view"),
  ACL.any
);
const RichText = asyncComponent(() => import("./rich-text-view"), ACL.admin);
const MobileAppRelevantCriterias = asyncComponent(
  () => import("./mobile-app-relevant-criteria-view"),
  ACL.admin
);
const HighlightSchedule = asyncComponent(
  () => import("./highlight-schedule-view"),
  ACL.any
);
const UserHighlightHistory = asyncComponent(
  () => import("./user-highlight-history-view"),
  ACL.admin
);
const AlgorithmConfig = asyncComponent(
  () => import("./algorithm-config-view"),
  ACL.admin
);
const AlgorithmEvolution = asyncComponent(
  () => import("./algorithm-evolution-view"),
  ACL.admin
);
const Register = asyncComponent(() => import("./register-view"), ACL.any);
const EmailTemplates = asyncComponent(
  () => import("./email-templates-view"),
  ACL.admin
);
const EditEmailTemplate = asyncComponent(
  () => import("./email-template-edit-view"),
  ACL.admin
);
const CustomTexts = asyncComponent(
  () => import("./custom-texts-view"),
  ACL.admin
);
const Gallery = asyncComponent(
  () => import("./gallery-view"),
  ACL.admin
);

export default function() {
  return (
    <ConnectedRouter history={history}>
      <div className="App">
        <Helmet>
          <meta
            http-equiv="Cache-Control"
            content="must-revalidate, max-age=3600"
          />
          <meta http-equiv="Pragma" content="no-cache" />
          <meta http-equiv="Expires" content="0" />
        </Helmet>
        <PromptProvider>
          <Switch>
            {/* REMOTE ROUTES */}
            <Route
              path={`${OWN_PREFIX}/remote/:id/answer/:token`}
              component={RemoteAnswer}
            />
            <Route
              path={`${OWN_PREFIX}/remote/questionnaire/completed`}
              component={RemoteAnswerCompleted}
            />
            <Route
              path={`${OWN_PREFIX}/remote/login/not-found`}
              component={NotFoundPage}
            />
            <Route
              path={`${OWN_PREFIX}/remote/login/user/:token/questionnaire/:tokenQuestionnaire`}
              component={RemoteLogin}
            />
            <Route
              path={`${OWN_PREFIX}/remote/password/user/:token/questionnaire/:tokenQuestionnaire`}
              component={RemotePassword}
            />
            <Route
              path={`${OWN_PREFIX}/remote/password-recovery`}
              component={RemotePasswordRecovery}
            />
            {/* USER */}
            <Route
              path={`${OWN_PREFIX}/user/:id/results/:questionnaireAnswerId`}
              component={Results}
            />
            <Route
              path={`${OWN_PREFIX}/user/:userId/answer-questionnaire/history/:id`}
              component={AnswerQuestionnaire}
            />
            <Route
              path={`${OWN_PREFIX}/user/:id/answer-questionnaire/history`}
              component={History}
            />
            <Route
              path={`${OWN_PREFIX}/user/:userId/answer-questionnaire/:id`}
              component={AnswerQuestionnaire}
            />
            <Route
              path={`${OWN_PREFIX}/user/:id/answer-questionnaire`}
              component={NewEntry}
            />
            {/* ADMIN */}
            <Route
              path={`${OWN_PREFIX}/admin/report/:questionnaireAnswerId/:reportNumber`}
              component={Report}
            />
            <Route path={`${OWN_PREFIX}/admin/invites`} component={Invites} />
            <Route
              path={`${OWN_PREFIX}/admin/backoffice-values`}
              component={BackofficeValues}
            />
            <Route
              path={`${OWN_PREFIX}/admin/users/backoffice`}
              component={UsersBackoffice}
            />
            <Route
              path={`${OWN_PREFIX}/admin/users/app`}
              component={UsersApp}
            />
            <Route path={`${OWN_PREFIX}/admin/pacients`} component={Pacients} />
            <Route
              path={`${OWN_PREFIX}/admin/email-templates`}
              component={EmailTemplates}
            />
            <Route
              path={`${OWN_PREFIX}/admin/email-template/:id`}
              component={EditEmailTemplate}
            />
            <Route
              path={`${OWN_PREFIX}/admin/custom-texts`}
              component={CustomTexts}
            />
            <Route
              path={`${OWN_PREFIX}/admin/questionnaires/:id`}
              component={EditQuestionnaire}
            />
            <Route
              path={`${OWN_PREFIX}/admin/questionnaires`}
              component={Questionnaires}
            />
            <Route
              path={`${OWN_PREFIX}/admin/questions`}
              component={Questions}
            />
            <Route
              path={`${OWN_PREFIX}/admin/answers-options`}
              component={AnswerOptions}
            />
            <Route
              path={`${OWN_PREFIX}/admin/gallery`}
              component={Gallery}
            />
            <Route path={`${OWN_PREFIX}/admin/criteria`} component={Criteria} />
            <Route
              path={`${OWN_PREFIX}/admin/criteria-usage`}
              component={CriteriaUsage}
            />
            <Route
              path={`${OWN_PREFIX}/admin/meta-skill`}
              component={MetaSkill}
            />
            <Route
              path={`${OWN_PREFIX}/admin/principle`}
              component={Principle}
            />{" "}
            <Route
              path={`${OWN_PREFIX}/admin/algorithm-config`}
              component={AlgorithmConfig}
            />
            <Route path={`${OWN_PREFIX}/admin/elements`} component={Elements} />
            <Route path={`${OWN_PREFIX}/admin/feelings`} component={Feelings} />
            <Route path={`${OWN_PREFIX}/admin/organs`} component={Organs} />
            <Route
              path={`${OWN_PREFIX}/admin/natural-elements`}
              component={NaturalElements}
            />
            <Route path={`${OWN_PREFIX}/admin/seasons`} component={Seasons} />
            <Route
              path={`${OWN_PREFIX}/admin/characters`}
              component={Characters}
            />
            <Route path={`${OWN_PREFIX}/admin/input`} component={Input} />
            <Route
              path={`${OWN_PREFIX}/admin/input/create`}
              component={CreateInput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/input/:id`}
              component={CreateInput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/algorithm-output`}
              component={AlgorithmOutput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/algorithm-output/:id`}
              component={CreateAlgorithmOutput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/algorithm-output/create`}
              component={CreateAlgorithmOutput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/general-output`}
              component={GeneralOutput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/general-output/:id`}
              component={CreateGeneralOutput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/general-output/create`}
              component={CreateGeneralOutput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/mobile-apps`}
              component={MobileAppsList}
            />
            <Route
              path={`${OWN_PREFIX}/admin/push-notifications`}
              component={PushNotifications}
            />
            <Route
              path={`${OWN_PREFIX}/admin/criteria-management`}
              component={CriteriaManagement}
            />
            <Route
              path={`${OWN_PREFIX}/admin/print/user/:id/results/:questionnaireAnswerId`}
              component={PrintAnalysis}
            />
            <Route
              path={`${OWN_PREFIX}/admin/dashboard`}
              component={AdminDashboard}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/apk`}
              component={AppView}
            />
            <Route
              path={`${OWN_PREFIX}/admin/element/:id/attributes`}
              component={Attributes}
            />
            <Route
              path={`${OWN_PREFIX}/admin/user/:id/profile`}
              component={Profile}
            />
            {/* APP */}
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/user/:userId/algorithm-evolution`}
              component={AlgorithmEvolution}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/user/:userId/highlight-history`}
              component={UserHighlightHistory}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/report/:questionnaireAnswerId/user/:userId`}
              component={MobileAppHistoryReport}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/mobile-app-relevant-criterias`}
              component={MobileAppRelevantCriterias}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/rich-text`}
              component={RichText}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/results/:questionnaireAnswerId/user/:userId`}
              component={MobileAppHistoryResults}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/questionnaire/:questionnaireAnswerId`}
              component={MobileAppHistoryQuestionnaire}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/mobile-app-free-criterias`}
              component={MobileAppFreeCriterias}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/push-notifications`}
              component={MobileAppPushNotifications}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/push-notifications`}
              component={MobileAppPushNotifications}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/staticString`}
              component={StaticString}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/highlight-schedule`}
              component={HighlightSchedule}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/highlight/question`}
              component={MobileAppHighlightQuestion}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/highlight/questionnaire`}
              component={MobileAppHighlightQuestionnaire}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/history/user/:userId`}
              component={MobileAppHistoryUser}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/history`}
              component={MobileAppHistory}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/daily-challenge`}
              component={MobileAppDailyChallenge}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/highlight-type`}
              component={MobileAppHighlightType}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/highlight-output`}
              component={MobileAppHighlightOutput}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/users`}
              component={MobileAppUser}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/character`}
              component={TextConfigCharacter}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/natural-element`}
              component={TextConfigNaturalElement}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/season`}
              component={TextConfigSeason}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/organ`}
              component={TextConfigOrgan}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/feeling`}
              component={TextConfigFeeling}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/element`}
              component={TextConfigElement}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/principle`}
              component={TextConfigPrinciple}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/meta-skill`}
              component={TextConfigMetaSkill}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/criteria-usage`}
              component={TextConfigCriteriaUsage}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/text-config/criteria`}
              component={TextConfigCriteria}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/env`}
              component={MobileAppEnv}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/user-criteria-app`}
              component={UserCriteriaApp}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/mobile-answer-component`}
              component={MobileAnswerComponent}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id/apk`}
              component={AppView}
            />
            <Route
              path={`${OWN_PREFIX}/admin/app/:id`}
              component={MobileAppDashboard}
            />
            {/* MAIN SITE ROUTES */}
            <Route
              path={`${OWN_PREFIX}/remote/register/questionnaire`}
              component={Register}
            />
            <Route
              path={`${OWN_PREFIX}/remote/login/questionnaire`}
              component={LoginFromSite}
            />
            <Route
              path={`${OWN_PREFIX}/remote/pre-payment`}
              component={PrePaymentInvite}
            />
            {/* OTHER */}
            <Route
              path={`${OWN_PREFIX}/password-recovery/success`}
              component={NewPasswordChanged}
            />
            <Route
              path={`${OWN_PREFIX}/new-password/:token`}
              component={NewPassword}
            />
            <Route
              path={`${OWN_PREFIX}/password-recovery`}
              component={PasswordRecovery}
            />
            <Route exact path={`${OWN_PREFIX}/login`} component={Login} />
            <Route exact path={`${OWN_PREFIX}/`} component={Login} />
            {/* Error Page */}
            <Route component={NotFoundPage} />
            {/* UNUSED */}
            {/* <Route path={`${OWN_PREFIX}/admin/test`} component={Testing} />
              <Route
                path={`${OWN_PREFIX}/admin/users/:userId/answers`}
                component={UsersAnswers}
              /> */}
          </Switch>
        </PromptProvider>
      </div>
    </ConnectedRouter>
  );
}
