import { createSelector } from "reselect";

/**
 * Direct selector to the login state domain
 */

const selectAuthDomain = state => state.global.auth;

export const makeSelectLoading = () =>
  createSelector(selectAuthDomain, substate => substate.loading);

export const makeSelectIsAuth = () =>
  createSelector(selectAuthDomain, substate => substate.auth);

export const makeSelectError = () =>
  createSelector(selectAuthDomain, substate => substate.error);

export const makeSelectUsername = () =>
  createSelector(selectAuthDomain, substate => substate.username);

export const makeSelectRole = () =>
  createSelector(selectAuthDomain, substate => substate.role);

export const makeSelectToken = () =>
  createSelector(selectAuthDomain, substate => substate.token);
