/**
 * Combine all reducers in this file and export the combined reducers.
 */

import authReducer from "../lib/providers/auth-provider/reducer";
import entityReducer from "../entities/entity-provider/";
import languageProviderReducer from "../translations/language-provider/reducer";
import paginationReducer from "../lib/providers/pagination-provider/reducers";
import routeReducer from "./routeReducer.js";
import { reducer as formReducer } from "redux-form";

import { combineReducers } from "redux";

export default function createReducer() {
  const obj = {
    route: routeReducer,
    form: formReducer,
    pagination: paginationReducer,
    entities: entityReducer(),
    global: combineReducers({
      auth: authReducer,
      language: languageProviderReducer
    })
  };
  return combineReducers(obj);
}
