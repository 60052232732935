// Imports
import ptPT from "./pt-PT";
import enUS from "./en-US";
import frFR from "./fr-FR";
import keys from "./keys";

export const fallBackLanguage = "pt-PT";

export const defaultLanguages = {
  pt: "pt-PT",
  en: "en-US",
  fr: "fr-FR"
};

const messages = {
  "en-US": enUS,
  "pt-PT": ptPT,
  "fr-FR": frFR
};

export default messages;

export function addTranslations(language, newMessages) {
  for (let attr in newMessages) {
    if (!messages[language])
      throw new Error(`Language ${language} doesn't exists. Please add it.`);

    if (newMessages.hasOwnProperty(attr)) {
      if (messages[language][attr])
        console.warn(`Duplicated translation ${attr}. Choose another name.`);

      messages[language][attr] = newMessages[attr];
    }
  }
}

export const globalKeys = keys;
