import React from "react";
import { Menu } from "antd";

class ConsultantMenu extends React.Component {
  render() {
    const { selectedKeys, links } = this.props;
    const key = (selectedKeys || [])[0];
    const openKeys =
      key &&
      key
        .split(".")
        .slice(0, -1)
        .reduce((keys, _, i, arr) => {
          keys.push(arr.slice(0, i + 1).join("."));
          return keys;
        }, []);
        
    return (
      <Menu
        key={"Menu"}
        style={{ background: "transparent" }}
        theme="light"
        mode="inline"
        defaultSelectedKeys={selectedKeys || []}
        defaultOpenKeys={openKeys}
      >
        <Menu.Item key="A4.3.1">{links.consultantInvite}</Menu.Item>
        <Menu.Item key="A1">{links.history}</Menu.Item>
      </Menu>
    );
  }
}

export default ConsultantMenu;
