/*
 *
 * language-provider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import PropTypes from "prop-types";
import React from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { makeSelectLocale, makeSelectMessages } from "./selectors";

import messages, { defaultLanguages } from "./translations";

export class LanguageProvider extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    const translations =
      messages[this.props.locale] ||
      messages[defaultLanguages[this.props.locale]];
    const dinamicMessages = this.props.messages;

    for (let id in dinamicMessages[this.props.locale]) {
      translations[id] = dinamicMessages[this.props.locale][id];
    }

    return (
      <IntlProvider
        locale={this.props.locale}
        key={this.props.locale}
        messages={translations}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.element.isRequired
};

const mapStateToProps = createSelector(
  makeSelectLocale(),
  makeSelectMessages(),
  (locale, messages) => ({
    locale,
    messages
  })
);

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageProvider);
