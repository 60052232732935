import {
  ADD_PAGE,
  CLEAR_PAGES,
  DELETE_PAGE,
  MOVE_BACK,
  MOVE_FIRST,
  MOVE_LAST,
  MOVE_NEXT,
  MOVE_TO,
  SETUP_PAGINATION
} from "./constants";

/****
 * TODO: otimizar meta data do add para melhor formato
 */

const initialState = {};

export default function(state = initialState, action) {
  let pagination;
  switch (action.type) {
    case SETUP_PAGINATION:
      const paginationObj = {
        pages: new Map(),
        currentPageNumber: null,
        pageSize: null,
        rowCount: null,
        pageCount: null
      };

      return { ...state, [action.payload.key]: paginationObj };

    case ADD_PAGE:
      pagination = state[action.payload.key];

      const paginationObj2 = {
        pages: new Map(),
        currentPageNumber: null,
        pageSize: null,
        rowCount: null,
        pageCount: null
      };

      if (!pagination) {
        pagination = paginationObj2;
      }

      pagination.pages.set(
        action.payload.meta.page,
        action.payload.pageOrdered
      );
      pagination.currentPageNumber = action.payload.meta.page;
      pagination.pageSize = action.payload.meta.pageSize;
      pagination.rowCount = action.payload.meta.rowCount;
      pagination.pageCount = action.payload.meta.pageCount;

      return { ...state, [action.payload.key]: { ...pagination } };

    case DELETE_PAGE:
      pagination = state[action.payload.key];
      if (pagination) {
        pagination.pages.delete(action.payload.pageNumber);
      }

      return { ...state, [action.payload.key]: { ...pagination } };

    case CLEAR_PAGES:
      delete state[action.payload.key];

      return { ...state };

    case MOVE_TO:
      pagination = state[action.payload.key];
      if (
        pagination &&
        action.payload.page > 0 &&
        action.payload.page <= pagination.pageCount
      ) {
        pagination.page = action.payload.page;
      }

      return { ...state, [action.payload.key]: { ...pagination } };

    case MOVE_FIRST:
      pagination = state[action.payload.key];
      if (pagination) {
        pagination.page = 1;
      }

      return { ...state, [action.payload.key]: { ...pagination } };

    case MOVE_LAST:
      pagination = state[action.payload.key];
      if (pagination) {
        pagination.page = pagination.pageCount;
      }

      return { ...state, [action.payload.key]: { ...pagination } };

    case MOVE_NEXT:
      pagination = state[action.payload.key];
      if (pagination) {
        pagination.page = Math.min(pagination.page + 1, pagination.pageCount);
      }

      return { ...state, [action.payload.key]: { ...pagination } };

    case MOVE_BACK:
      pagination = state[action.payload.key];
      if (pagination) {
        pagination.page = Math.max(pagination.page - 1, 1);
      }

      return { ...state, [action.payload.key]: { ...pagination } };

    default:
      return state;
  }
}
